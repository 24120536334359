<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title">
						<span class="mem_info"></span>
						<p v-if="registerFlag == 'create'">회원가입/{{mberInfo.title}}</p>
						<p v-if="registerFlag == 'update'">{{mberInfo.title}}</p>
					</div>
					<div class="reg-step" v-if="registerFlag == 'create'"><img src="/images/mem/process_bar_04.png" alt="절차"/></div>
				</div>
				<div class="contents-body">
					<div class="Area">
						<div class="Item">
							<table class="tb">
								<colgroup>
									<col width="230">
									<col width="300">
									<col width="217">
									<col width="*">
								</colgroup>
								<tbody>
									<tr>
										<th>
											<span>*</span>
											<label for="txt01"><nbsp/>성명</label>
										</th>
										<td colspan="2">
										<InputComp v-model="mberInfo.mberNm" :readonly="!this.forwardData.pass" maxlength="50" title="성명" :inputable="inputable.name" :rules="{'required':true}" />
											<!-- <InputComp v-model="mberInfo.mberNm" :classNm="registerFlag == 'update' && mberInfo.mberDivCd == '31' ? 'mem_id' : ''" :readonly="!this.forwardData.pass" maxlength="50" title="성명" :inputable="inputable.name" :rules="{'required':true}" />
											<nbsp/><div class="check-btn" style="text-align: center;" @click="nameCng('name')" v-if="registerFlag == 'update' && mberInfo.mberDivCd == '31'">담당자 변경</div> -->
										</td>
									</tr>
									<tr>
										<th>
											<span>*</span>
											<label for="txt02"><nbsp/>회원아이디</label>
										</th>
										<td colspan="2">
											<InputComp v-model="mberInfo.id" @keyup="valueCheck($event)" :classNm="registerFlag == 'create' ? 'mem_id' : ''" :readonly="registerFlag != 'create'" maxlength="16" @input="temp.idDupChkPass = false" title="아이디" :inputable="inputable.id" :rules="{'required':true, 'minLength':4, 'maxLength':16}" placeholder="4~16자의 영문 소문자 / 숫자만 사용가능 합니다!"/>
											<nbsp/><div class="check-btn" @click="dupChk()" v-if="registerFlag == 'create'">아이디 중복확인</div>
										</td>
									</tr>
									<tr>
										<th>
											<span>*</span>
											<label for="psw01"><nbsp/>비밀번호</label>
										</th>
										<td colspan="2">
											<InputComp type="password" v-model="mberInfo.password" maxlength="16" title="비밀번호" :rules="{'required':temp.pwdReq, 'minLength':8, 'maxLength':16}" placeholder="8~16자의 영문 / 숫자 / 특수문자를 모두 사용하세요!"/>
										</td>
									</tr>
									<tr>
										<th>
											<span>*</span>
											<label for="psw02"><nbsp/>비밀번호 확인</label>
										</th>
										<td colspan="2">
											<InputComp type="password" v-model="mberInfo.passwordCfm" maxlength="16" title="비밀번호 확인" :rules="{'required':temp.pwdReq,'minLength':8,'maxLength':16,'func' : {rule:same, args:mberInfo.password}}"/>
										</td>
									</tr>
									<tr>
										<th>
											<span>*</span>
											<label for="hpNo"><nbsp/>휴대폰번호</label>
										</th>
										<td colspan="2">
											<InputComp type="cell"  v-model="mberInfo.hpNo" :classNm="registerFlag == 'update' ? 'mem_id' : ''" title="휴대폰번호" :rules="{'required':true}" :readonly="!this.forwardData.pass"/>
											<nbsp/><div class="check-btn" style="text-align: center;" @click="nameCng('hp')" v-if="registerFlag == 'update'">번호 변경하기</div>
										</td>
									</tr>
									<tr>
										<th>
											<span>*</span>
											<label for="txt04"><nbsp/>이메일</label>
										</th>
										<td colspan="2" class="email">
											<InputComp v-model="temp.emailId" classNm="mem_id certi" @input="emailDataInit();" title="이메일" maxlength="30" :inputalbe="inputable.email" :rules="{'required':true}" :showError="false" /> &commat; <InputComp classNm="mem_id certi" v-model="temp.domainTxt" @input="emailDataInit();" maxlength="15" :readonly="temp.domainSlt == '' ? false : true" title="도메인" :inputalbe="inputable.domain" :rules="{'required':true}" :showError="false"/>
											<nbsp/>
											<div class="select_wrap" @change="temp.domainTxt = temp.domainSlt; emailDataInit();">
												<select v-model="temp.domainSlt">
													<option value="" selected>직접입력</option>
													<option :value="domain" v-for="domain in emailDomaiList" :key="domain">{{domain}}</option>
												</select>
											</div>
											<nbsp/><div class="check-btn certi" @click="sendEmail()">이메일 인증하기</div>
											<div v-show="temp.inputCertShow">
												<InputComp classNm="mem_id" placeholder="인증번호를 입력해주세요" v-model="temp.inputCertTxt" />
												<nbsp/><div class="check-btn" style="text-align: center;" @click="emailCert()">확인</div>
											</div>
											<br/><span class="desc" v-if="temp.emailId.message || temp.domainTxt.message" style="color:red; font-size:80%;">{{temp.emailId.message || temp.domainTxt.message}}</span>
											<br/><span class="desc">※ 이메일 주소 입력 후 이메일 인증하시기 바랍니다!</span>
										</td>
									</tr>
									<tr>
										<th>
											<span>*</span>
											<label for="txt05"><nbsp/>이메일 수신여부</label>
										</th>
										<td colspan="2">
											<SelectComp type="radio" list="Y:예,N:아니요" v-model="mberInfo.emailYn" defValue="Y"/>
											<br/>
											<span class="desc">※ 프로젝트 지원 또는 프로젝트 인터뷰 요청, 적립포인트 등 다양한 정보를 받으실 수 있습니다.</span>
										</td>
									</tr>
									<tr>
										<th>
											<span>*</span>
											<label for="txt06"><nbsp/>SMS 수신여부</label>
										</th>
										<td colspan="2">
											<SelectComp type="radio" list="Y:예,N:아니요" v-model="mberInfo.smsYn" defValue="Y"/>
											<br/>
											<span class="desc">※ 프로젝트 지원 또는 프로젝트 인터뷰 요청, 적립포인트 등 다양한 정보를 받으실 수 있습니다.</span>
										</td>
									</tr>
									<!-- <tr v-if="mberInfo.mberDivCd == '11'">
										<th>
											<span>*</span>
											<label for="txt07"><nbsp/>회원소속사</label>
										</th>
										<td colspan="2">
											<input type="radio" id="rd05" v-model="temp.mberCorpDiv" value="1" @change="mberCorpDivCng()">
											<label for="rd05">
												<span class="rd"></span>
												프로엔솔루션
											</label>
											<input type="radio" id="rd06" v-model="temp.mberCorpDiv" value="2" @change="mberCorpDivCng()">
											<label for="rd06">
												<span class="rd"></span>
												기타소속사
												<AutoComplete type="text" v-model="temp.corpInfo" :list="corpList" listText="corpNm" @autoComplete="setCorpInfo" v-if="temp.mberCorpDiv == '2'" title="소속사" :rules="{'required':true}">
													<template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
												</AutoComplete>
											</label>
											<input type="radio" id="rd07" v-model="temp.mberCorpDiv" value="3" @change="mberCorpDivCng()">
											<label for="rd07" class="rd">
												<span class="rd"></span>
												소속없음
											</label>
										</td>
									</tr>

									<tr v-if="mberInfo.mberDivCd == '11'">
										<th>
											<span>*</span>
											<label for="txt08"><nbsp/>직원구분</label>
										</th>
										<td colspan="2">
											<SelectComp type="radio" cdId="PRO102" v-model="mberInfo.empDivCd" defValue="01" :disabled="temp.mberCorpDiv == '3'"/>
										</td>
									</tr> -->

									<tr v-if="mberInfo.mberDivCd == '11'">
										<th>
											<span>*</span>
											<label for="txt09"><nbsp/>생년월일</label>
										</th>
										<td colspan="2">
											<InputComp type="date" classNm="birth" v-model="mberInfo.birthYyyymmdd" title="입력 예) 20020101" format="yyyy년 MM월 dd일" :readonly="!this.forwardData.pass"/>
										</td>
									</tr>

									<tr v-if="mberInfo.mberDivCd == '11'">
										<th>
											<span>*</span>
											<label for="txt10"><nbsp/>성별</label>
										</th>
										<td colspan="2">
											<SelectComp type="radio" list="F:여,M:남" v-model="mberInfo.gender" defValue="F" :disabled="!this.forwardData.pass"/>
										</td>
									</tr>

									<!-- <tr v-if="mberInfo.mberDivCd == '31'">
										<th>
											<span>*</span>
											<label for="txt11"><nbsp/>산업분야</label>
										</th>
										<td colspan="2">
											<SelectComp cdId="PRO105" v-model="mberInfo.indtypeClassCd" title="산업분야" :rules="{'required':true}"/>
										</td>
									</tr> -->

									<tr v-if="'21, 22, 31'.indexOf(mberInfo.mberDivCd) > -1">
										<th>
											<span>*</span>
											<label for="txt12"><nbsp/>회사명</label>
										</th>
										<td colspan="2">
											<AutoComplete type="text" class="company" v-model="temp.corpInfo" :list="corpList" listText="corpNm" @autoComplete="setCorpInfo" v-if="'21, 22'.indexOf(mberInfo.mberDivCd) > -1" :readonly="registerFlag != 'create'"  title="회사명" :rules="{'required':temp.demCorpReq}">
												<template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
											</AutoComplete>
											<AutoComplete type="text" class="company" v-model="temp.corpInfo" :list="corpList" listText="corpNm" @autoComplete="setCorpInfo" v-if="'31'.indexOf(mberInfo.mberDivCd) > -1" :readonly="registerFlag != 'create'"  title="회사명" :rules="{'required':true}">
												<template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
											</AutoComplete>
											<!-- <InputComp v-model="mberInfo.corpNm" maxlength="50" :readonly="registerFlag == 'update'" v-else-if="mberInfo.mberDivCd == '31'" title="회사명" :rules="{'required':true}"/> -->
										</td>
									</tr>

									<tr v-if="mberInfo.mberDivCd == '22'">
										<th>
											<span>*</span>
											<label for="txt13"><nbsp/>부서명</label>
										</th>
										<td colspan="2">
											<InputComp classNm="department" v-model="mberInfo.deptNm" title="부서명" :rules="{'required':true}"/>
											<span class="rank">*</span>
											<label for="txt14" class="rank"><nbsp/>직급</label>
											<nbsp/><nbsp/>
											<InputComp classNm="rank" v-model="mberInfo.positionNm" title="직급" :rules="{'required':true}"/>
										</td>
									</tr>

									<!-- <tr v-if="mberInfo.mberDivCd == '31'">
										<th>
											<span>*</span>
											<label for="txt15"><nbsp/>사업자등록번호</label>
										</th>
										<td colspan="2">
											<InputComp type="bizNo" v-model="mberInfo.bizRegNo" :readonly="registerFlag == 'update'" title="사업자등록번호" :rules="{'required':true}"/>
										</td>
									</tr>

									<tr v-if="mberInfo.mberDivCd == '31'">
										<th rowspan="3">
											<span>*</span>
											<label for="txt01"><nbsp/>회사주소</label>
										</th>
										<td colspan="2">
											<InputComp classNm="mem_id" id="corpZipCd" v-model="mberInfo.corpZipCd" title="우편번호" :rules="{'required':true}" readonly/>
											<nbsp/><div class="check-btn" @click="jusoPop()" style="text-align: center;">주소검색</div>
										</td>
									</tr>

									<tr v-if="mberInfo.mberDivCd == '31'">
										<td colspan="2">
											<InputComp v-model="mberInfo.corpAddr" title="도로명주소" :rules="{'required':true}" readonly />
										</td>
									</tr>

									<tr v-if="mberInfo.mberDivCd == '31'">
										<td colspan="2">
											<InputComp v-model="mberInfo.corpAddrDtl" title="상세주소" :rules="{'required':true}" readonly />
										</td>
									</tr> -->
								</tbody>
							</table>
							<!-- 취소/완료 버튼 -->
							<div class="agree-btn" :class="registerFlag == 'update' ? 'control' : ''">
								<div :class="registerFlag == 'create' ? 'btn-cancel' : 'btn'" @click="btnCancel()">취소</div>
								<div class="btn-complete" @click="btnSubmit()" v-if="registerFlag == 'create'">완료</div>
								<div class="btn" style="background-color: #FF681D;" @click="btnSubmit()" v-if="registerFlag == 'update'">회원정보 변경</div>
								<!-- <button @click="btnWithdrawal()" v-if="registerFlag == 'update'">회원탈퇴 - 임시</button> -->
								<div class="btn" @click="btnWithdrawal()" v-if="registerFlag == 'update'">회원탈퇴</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			registerFlag : 'create',
			pageType:'cham',
			
			// 회원정보
			mberInfo : {
				id : '',
				password : '',
				passwordCfm : '',
				emailYn : 'Y',
				smsYn : 'Y',
				corpSeq : '',
				bizRegNo : '',
				email : '',
				mberDivCd : '',
				indtypeClassCd : '',

				// 회원소속사 변경 관리
				// preCorpSeq : '',
				// aftCorpSeq : '',
				mberCorpDiv: '',
				//classDivCd : '',
				//apprCorpList : []
			},

			// 임시
			temp : {
				mberDiv : '',
				mberCorpDiv : '1',
				corpInfo : '',
				inputCertShow : false,
				pwdReq : true,
				emailId : '',
				domainSlt : '',
				domainTxt : '',
				idDupPass : true,
				emailPass : true,
				tecCorpReq : false,
			},

			// oriMberCorpDiv: '',
			// oriCorpInfo: '',
			
			
			corpList : [],
			userInfo : {},
			kcpcert : {},
			forwardData : {},

			inputable : {
				name : ["han"],
				id : ["lowr", "uppr","num"],
				email : "A-Za-z_.",
				domain : "a-z.",
				birth : ["num"]
			},

			// 이메일 selectBox
			emailDomaiList : ['naver.com', 'gmail.com', 'daum.net', 'nate.com', 'empas.com']
		}
	},

	beforeMount() {
		var viewNm = this.$route.name;
	
		if(viewNm == 'MEM004M03') { // 회원가입 정보입력 시
			this.forwardData = this.$route.params.secrets;
			
			if(this.forwardData) {
				this.setMberInfo();

				// if(this.mberInfo.mberDivCd == '11') {
				// 	this.pageType = '';
				// 	this.corpSearch('20');
				// 	this.mberInfo.corpSeq = '1';
				// }else 
				
				if('21, 22'.indexOf(this.mberInfo.mberDivCd) > -1) {
					this.corpSearch('10');
				}else if('31'.indexOf(this.mberInfo.mberDivCd) > -1){
					this.corpSearch('20');
				}
			}else {
				this.$router.replace('MEM003M01');
			}
		}else { // 회원정보 변경시
			this.registerFlag = 'update';
			this.temp.pwdReq = false;
			
			if(viewNm == 'MYP102M01') {
				this.pageType = '';
				//this.corpSearch('20');
			}else if('MYP202M01, MYP302M01'.indexOf(viewNm) > -1) {
				this.corpSearch('10');
			}

			this.selectMberInfo();
		}

		//this.getCommonCode();
		
	},

	methods : {
		// 영문소문자 , 숫자 입력 체크
		valueCheck(e) {
			if (e.getModifierState("CapsLock")) {
				alert("CapsLock이 켜져있습니다.")
			}
		},
		setMberInfo() {
			this.mberInfo.mberDivCd = this.forwardData.mberDivCd;
			this.mberInfo.title = this.forwardData.title;
			this.mberInfo.stipList = this.forwardData.stipList;

			// 본인인증 data
			if(this.forwardData.kcpcert) {
				this.mberInfo.hpNo = this.forwardData.kcpcert.phone_no;
				this.mberInfo.mberNm = this.forwardData.kcpcert.user_name;
				this.mberInfo.birthYyyymmdd = this.forwardData.kcpcert.birth_day;
				this.mberInfo.gender = this.forwardData.kcpcert.sex_code == '01' ? 'M' : 'F';
				this.mberInfo.authCi = this.forwardData.kcpcert.ci;
				this.mberInfo.authDi = this.forwardData.kcpcert.di;
				this.mberInfo.up_hash = this.forwardData.kcpcert.up_hash;
			}

		},
		
		  

		// 아이디/이메일 중복체크
		dupChk() {
			if(!this.mberInfo.id) {
				alert("아이디를 입력하세요.");
				return false;
			}
			
			this.$.httpPost('/api/mem/chkDup', {id : this.mberInfo.id}, '')
				.then(res => {
					if(res.data.resultCode == '000') {
						if(res.data.data.dupChk == 0) {
							alert('사용가능한 아이디 입니다.');
							this.temp.idDupChkPass = true;
						}else {
							alert('이미 사용중인 아이디 입니다.');
							this.temp.idDupChkPass = false;
						}
					}
				});
		},

		emailMake() {
			this.mberInfo.email = this.temp.emailId + '@' + this.temp.domainTxt;
		},

		sendEmail() {
			if(!this.temp.emailId || !this.temp.domainTxt) {
				alert("이메일을 입력하세요.");
				return false;
			}

			if(this.temp.inputCertTxt) {
				this.emailDataInit();
			}

			var param = {};
			param.email = this.temp.emailId + '@' + this.temp.domainTxt;
			if(this.forwardData.pass) param.pass = this.forwardData.pass;

			this.$.httpPost('/api/mem/sendEmailCert', param)
				.then(rs => {
					if(rs.data.resultCode == '000') {
						this.temp.inputCertShow = true; 
						this.temp.emailHash = rs.data.emailHash;
					}
				})	
		},

		// 이메일 인증
		emailCert() {
			var param = {}
			param.email = this.temp.emailId + '@' + this.temp.domainTxt;
			param.emailHash = this.temp.emailHash;
			param.inputCertTxt = this.temp.inputCertTxt;

			this.$.httpPost('/api/mem/emailCertChk', param, '')
				.then(res => {
					if(res.data.resultCode == '000') {
						if(res.data.certRs == 'pass') {
							this.temp.inputCertShow = false;
							this.temp.emailCertPass = true;
							alert('이메일 인증 완료');
						}else {
							this.temp.emailCertPass = false;
							alert('인증번호가 일치하지 않습니다.');
						}
					}

					// this.$forceUpdate();
				});
		},

		// 이메일 인증 초기화
		emailDataInit() {
			if(this.temp.emailCertPass) {
				this.temp.inputCertTxt = '';
				this.temp.inputCertShow = false;
				this.temp.emailCertPass = false;
				this.temp.emailHash = '';
			}

			// 회원정보 수정시 이메일 인증처리
			if(this.temp.email && this.temp.emailId + '@' + this.temp.domainTxt == this.temp.email) {
				this.temp.emailCertPass = true;
			}
		},

		// 기업조회
		corpSearch(div) {
			this.$.httpPost('/api/mem/searchCorp', {corpDivCd : div, pageType : this.pageType}, '')
				.then(res => {
					if(res.data.resultCode == '000') {
						this.corpList = res.data.corpList;
					}
				});
		}, // corpSearch

		// 고객사/제휴사 선택
		setCorpInfo(corpInfo) {
			if(corpInfo) {
				this.temp.corpSeq = corpInfo.corpSeq;
				this.temp.corpNm = corpInfo.corpNm;
				this.temp.bizRegNo = corpInfo.bizRegNo;

				this.mberInfo.corpSeq = corpInfo.corpSeq;
				this.mberInfo.corpNm = corpInfo.corpNm;
				this.mberInfo.bizRegNo = corpInfo.bizRegNo;
			}else {
				this.mberInfo.corpSeq = '';
				this.mberInfo.corpNm = '';
				this.mberInfo.bizRegNo = '';
			}
		},

		// 비밀번호/비밀번호확인 일치 체크
		same(val, args){
			return val == (args || '') || '비밀번호와 일치하지 않습니다.';
		},
		
		// 비밀번호 형식 체크
		passChk() {
			const passTest = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()_+=-]).{8,16}$/;
			var passRes = passTest.test(this.mberInfo.password);

			if(passRes) {
				return true;
			} else {
				alert("비밀번호는 8~16자의 영문 / 숫자 / 특수문자를 모두 사용해야합니다!");
				return false;
			}
			
		},

		// 회원소속사 변경시
		mberCorpDivCng() {
			this.mberInfo.corpSeq = '';
			this.mberInfo.corpNm = '';
			this.mberInfo.bizRegNo = '';
			this.temp.corpInfo = '';

			if(this.temp.mberCorpDiv == '1') this.mberInfo.corpSeq = '1';
			
			if(this.temp.mberCorpDiv == '2' && this.temp.corpSeq) {
				this.mberInfo.corpSeq = this.temp.corpSeq;
				this.mberInfo.corpNm = this.temp.corpNm;
				this.mberInfo.bizRegNo = this.temp.bizRegNo;
			}

			if(this.temp.mberCorpDiv == '3') {
				this.mberInfo.empDivCd = '02';
			}
		},

		// 주소검색
		jusoPop() {
			this.$.jusoSearch(this, function(this_obj, res) {
				this_obj.mberInfo.corpZipCd 	= res.zipNo;
				this_obj.mberInfo.corpAddr		= res.roadAddrPart1;
				this_obj.mberInfo.corpAddrDtl 	= res.addrDetail + " " + res.roadAddrPart2;
				this_obj.$forceUpdate();
			});
		},

		// 조회
		selectMberInfo() {
			this.userInfo = this.$store.state.userInfo;

			this.$.httpPost('api/mem/selectMberInfo', this.userInfo, '')
				.then(res => {
					if(res.data.resultCode == '000') {
						this.mberInfo = res.data.mberInfo;
						//this.mberInfo.apprCorpList = [];
						//this.mberInfo.classDivCd = '';
						// this.oriMberCorpDiv = res.data.mberInfo.mberCorpDiv;
						// this.oriCorpInfo = res.data.mberInfo.corpNm;
						this.temp.mberCorpDiv = res.data.mberInfo.mberCorpDiv;
						this.temp.corpInfo = res.data.mberInfo.corpNm;
						this.temp.email = res.data.mberInfo.email;
						this.temp.emailCertPass = true;
						this.temp.emailId = res.data.mberInfo.emailId;
						this.temp.domainTxt = res.data.mberInfo.domain;

						//this.mberInfo.preCorpSeq = res.data.mberInfo.corpSeq;

						for(var i in this.emailDomaiList) {
							if(this.emailDomaiList[i] == res.data.mberInfo.domain) this.temp.domainSlt = res.data.mberInfo.domain;
						}

						if(this.mberInfo.mberDivCd == '11') {
							this.mberInfo.title = '개인정보 관리';
						}else {
							this.mberInfo.title = '정보관리';
						}
					}
				})
				.catch(() => {
					//console.log(err.message);
				})
		},

		// 본인인증
		nameCng(div) {
			this.$.kcpCert(this, function(this_obj, res) {
				this_obj.kcpcert = res;
				this_obj.mberchk(div);
			});			
		},

		mberchk(div) {
			var param = {
				di : this.kcpcert.di,
				mberDivCd : this.mberInfo.mberDivCd
			};

			this.$.httpPost('api/mem/memberChk', param,'')
				.then((res) => {
					//console.log('memberChk res', res);

					if(res.data.resultCode == '000') {
						if(res.data.idMap && div == 'name') {
							alert('이미 등록된 회원입니다.');
							return false;
						}else if(!res.data.idMap && div == 'hp') {
							alert("생성시 등록한 휴대폰 명의와 일치하지 않습니다.");
							return false;
						}else {
							this.mberInfo.hpNo = this.kcpcert.phone_no;
							this.mberInfo.mberNm = this.kcpcert.user_name;
							this.mberInfo.birthYyyymmdd = this.kcpcert.birth_day;
							this.mberInfo.gender = this.kcpcert.sex_code == '01' ? 'M' : 'F';
							this.mberInfo.authCi = this.kcpcert.ci;
							this.mberInfo.authDi = this.kcpcert.di;
							this.mberInfo.up_hash = this.kcpcert.up_hash;
						}
					}
				})
		},

		// 입력값 검사
		inputValidate() {
			if(this.mberInfo.id instanceof Error) {
				alert('아이디를 입력하지 않았습니다.');
				return false;
			}

			if(this.registerFlag == 'create') {
				if(!(this.mberInfo.id instanceof Error) && !this.temp.idDupChkPass) {
					alert('아이디 중복체크가 완료되지 않았습니다.');
					return false;
				}
			}

			if(this.mberInfo.password instanceof Error) {
				alert('비밀번호를 입력하지 않았습니다.');
				return false;
			}

			if(this.mberInfo.passwordCfm instanceof Error) {
				alert('비밀번호 확인을 체크해주세요.');
				return false;
			}

			if(!this.temp.emailCertPass) {
				alert('이메일 인증이 완료되지 않았습니다.');
				return false;
			}

			// if(this.temp.mberCorpDiv == '2' && this.temp.corpInfo == '') {
			// 	alert('소속사를 선택하지 않았습니다.');
			// 	return false;
			// }

			if(this.mberInfo.mberDivCd != '11' && this.temp.corpInfo == '') {
				alert('회사명을 선택하지 않았습니다.');
				return false;
			}

			return true;
		},

		// 만 19세 이상 확인
		ageChk() {
			var ageRes = true;
			
			if(this.mberInfo.birthYyyymmdd && this.mberInfo.mberDivCd == '11') {
				var date = new Date();
				var year = date.getFullYear();
				var month = (date.getMonth() + 1);
				var day = date.getDate();
				
				if (month < 10) month = '0' + month;
				if (day < 10) day = '0' + day;
				
				var monthDay = month + day;
				var birth = this.mberInfo.birthYyyymmdd;
				
				var birthdayy = birth.substr(0, 4);
				var birthdaymd = birth.substr(4, 4);
				var age = monthDay < birthdaymd ? year - birthdayy - 1 : year - birthdayy;

				if(age < 19) {
					alert("만 19세 미만은 회원가입을 하실 수 없습니다. ");
					ageRes = false;
				}
			}

			return ageRes;
		},

		// 등록/변경
		btnSubmit() {
			// validation
			var val = this.inputValidate();
			if(!val) return;

			// 나이 체크
			val = this.ageChk();
			if(!val) return;

			//비밀번호 형식 체크
			val = this.passChk();
			if(!val) return;

			this.sendData();

			// 공급기업 중복체크
			// if(this.registerFlag == 'create' && this.mberInfo.mberDivCd == '31') {
			// 	this.$.httpPost('/api/mem/corpMberDupChk', {bizRegNo : this.mberInfo.bizRegNo})
			// 		.then(res => {
			// 			console.log('corpMberDupChk RESULT', res);

			// 			if(res.data.dupChk.corpCnt > 0) {
			// 				var scoDup = 'pass';
			// 				if(res.data.dupChk.mberWithYn > 0) scoDup = 'dupl';

			// 				this.$.popup('/mem/MEM004P03', scoDup)
			// 					.then(rslt => {
			// 						if(rslt) {
			// 							this.mberInfo.corpSeq = res.data.dupChk.corpSeq;
			// 							this.sendData();
			// 						}
			// 					})
			// 			}else {
			// 				this.sendData();
			// 			}
			// 		})
			// }else {
			// 	this.sendData();
			// }

			/*
			var param = {
				mberNm: this.mberInfo.mberNm , 
				gender: this.mberInfo.gender, 
				empDivCd: this.mberInfo.empDivCd, 
				mberCorpDiv: this.temp.mberCorpDiv, 
				corpNm: this.mberInfo.corpNm,
				registerFlag : this.registerFlag
			}
			
			this.mberInfo.mberCorpDiv= this.temp.mberCorpDiv; 
			this.mberInfo.aftCorpSeq = this.mberInfo.corpSeq;

			this.sendData();

			if(this.registerFlag == 'create' && this.mberInfo.mberDivCd == '11'){
				this.$.popup('/mem/MEM004P04', param)
				.then(res=>{
					if(res){
						this.mberInfo.classDivCd = '01';
						this.sendData();
					}
				})

			}
			
			else if(this.registerFlag == 'update' && this.mberInfo.mberDivCd == '11' && (this.mberInfo.corpSeq != this.mberInfo.preCorpSeq)){

				this.$.httpPost('api/mem/asgApprDupChk', { mberSeq : this.mberInfo.mberSeq })
					.then(res => {
						if(res.data.asgApprRegYn == 'N'){
							
							this.temp.mberCorpDiv = this.oriMberCorpDiv;
							this.temp.corpInfo = this.oriCorpInfo;
							this.mberInfo.corpSeq = this.mberInfo.preCorpSeq;

							alert("미승인된 승인 요청 건이 있습니다.\n승인 완료 후 요청이 가능합니다!");

						}else{
							this.$.popup('/mem/MEM004P04', param)
							.then(res=>{
								if(res){
									this.mberInfo.classDivCd = '02';
									this.sendData();
								}
							})
							
						}
						
					});
			}
			else{
				this.sendData();
			}
			*/
		},

		sendData() {
			var url = 'insertMberInfo';
			if(this.registerFlag == 'update') url = 'updateMberInfo';

			if(this.temp.inputCertTxt) this.mberInfo.inputCertTxt = this.temp.inputCertTxt;
			if(this.temp.emailHash) this.mberInfo.emailHash = this.temp.emailHash;
			this.mberInfo.email = this.temp.emailId + '@' + this.temp.domainTxt;

			// if(this.mberInfo.preCorpSeq != '' ) { this.mberInfo.apprCorpList.push(this.mberInfo.preCorpSeq); }
			// if(this.mberInfo.aftCorpSeq != '' ) { this.mberInfo.apprCorpList.push(this.mberInfo.aftCorpSeq); }

			var param = {};
			param.mberInfo = this.mberInfo;
			
			this.$.httpPost('/api/mem/' + url, param, '')
				.then(res => {
					if(res.data.resultCode == "000"){
						if(this.registerFlag == 'create') {
							//var sendData = { mberSeq : res.data.data.mberSeq };

							this.$router.push({name : 'MEM004M04', params : {forwardData : this.mberInfo.title}});
						}else {
							alert('정보가 변경되었습니다.');
							//this.$router.push(this.$route.name);
							//this.$router.go();
							this.$router.push('/');
	
						}
					}else {
						alert(res.data.message);
					}
				})
				.catch(err => {
					alert(err.response.data.error_description);
				});
		},

		btnCancel() {
			if(this.registerFlag == 'create') {
				this.$router.replace('MEM003M01');
			}else {
				this.$router.go(-1);
			}
		},

		btnWithdrawal() {
			var param = {};
			param.mberSeq = this.mberInfo.mberSeq;
			param.id = this.mberInfo.id;

			this.$.popup('/tec/myp/MYP102P01', param);

			// if(confirm('회원탈퇴 하시겠습니까?')) {
			// 	this.$.httpPost('api/mem/memberWithdrawal')
			// 	.then(() => {
			// 		alert('회원탈퇴 되었습니다.');
			// 		this.$.logout();
			// 	})
			// }
		},
	}
}

</script>

<style>

</style>